import React from 'react';
import {
    MDBContainer
} from 'mdb-react-ui-kit';
import LinearProgress from '@mui/material/LinearProgress';

const Loading = () => (
    <MDBContainer>
        <h5 className="text-center my-4 display-6">Fetching Notes</h5>
        <LinearProgress />
    </MDBContainer>
);

export default Loading;