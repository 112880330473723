import React from 'react';
import {
    MDBRipple,
    MDBContainer,
    MDBBtn,
    MDBIcon
} from 'mdb-react-ui-kit';
import { motion } from 'framer-motion';
import h from '../../../utilities/helpers';
import t from '../../../utilities/transitions';

class FileList extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * imageHovered: false | String - The md5 hash of the file that the user is currently hovering over
             */
            imageHovered: false
        }
    }

    /**
     * 
     * @param {String} md5 - md5 hash of the file that the user is hovering over with their cursor
     * 
     * Triggered when the user hovers over a file with their cursor
     * Sets into state that file as being hovered (displays the trash can button to remove the file)
     */
    setImageHovered = md5 => {
        if (!this.state.working) this.setState({
            ...this.state,
            imageHovered: md5
        });
    }

    /**
     * 
     * @param {File} file - Javascript file object
     * @returns A file thumbnail/preview depending on the type of file that it is
     */
    getFileThumbnail = file => {
        let src;
        switch(file.type.split('/')[0]){
            case 'video':
                src = file.main ? (process.env.REACT_APP_BUCKET_HOST + "/videos/" + file.main) : file.path;
                return (
                    <MDBRipple onClick={() => this.selectMedia(file.md5)} className="h-100 w-100 d-flex justify-content-center align-items-center position-relative cursor-pointer">
                        <video className="position-absolute max-w-100 max-h-100" src={src} id={file.md5}></video>
                        {this.state.mediaPlaying === file.md5 ?
                        <>
                            {this.state.imageHovered === file.md5 ?
                            <motion.i transition={t.transition} exit={t.fade_out} animate={t.normalize} initial={t.fade_out} style={{color: 'rgba(255, 255, 255, 0.5)', zIndex: 30}} className="fas fa-pause fa-5x"></motion.i> : <></>}
                        </> :
                        <i style={{color: 'rgba(255, 255, 255, 0.5)', zIndex: 30}} className="fas fa-play fa-5x"></i>}
                    </MDBRipple>
                );
            case 'audio':
                src = file.main ? (process.env.REACT_APP_BUCKET_HOST + "/audio/" + file.main) : file.path;
                return (
                    <>
                        <audio src={src} id={file.md5}></audio>
                        <MDBRipple onClick={() => this.selectMedia(file.md5)} className="h-100 d-flex w-100 justify-content-center align-items-center cursor-pointer">
                            {this.state.mediaPlaying === file.md5 ?
                            <i className="fas fa-pause fa-5x"></i> :
                            <i className="fas fa-play fa-5x"></i>}
                        </MDBRipple>
                    </>
                );
            case 'image':
                src = file.main ? (process.env.REACT_APP_BUCKET_HOST + "/images/" + file.main) : file.path;
                return <div className="fit-images cursor-default" style={{backgroundImage: `url("${src}")`}}></div>
        }
    }

    /**
     * 
     * @param {String} md5 - md5 hash of the file selected
     * 
     * Triggered when the user clicks an audio or video file
     * Stop the file that is currently playing, if any
     * Play the file
     */
    selectMedia = md5 => {
        if (this.state.mediaPlaying) document.getElementById(this.state.mediaPlaying).pause();
        this.setState({
            ...this.state,
            mediaPlaying: (this.state.mediaPlaying === md5) ? '' : md5
        }, () => {
            if (this.state.mediaPlaying) document.getElementById(this.state.mediaPlaying).play();
        });
    }

    render(){
        return (
            <MDBContainer fluid className="mb-2">
                <div className="row">
                    {this.props.files.map((file, f) => (
                        <motion.div 
                            transition={t.transition} 
                            exit={t.fade_out} 
                            animate={t.normalize} 
                            initial={t.fade_out} 
                            id={`file-${file.md5}`} 
                            key={file.md5} 
                            className="col-12 col-md-4 col-lg-3 mt-3"
                        >
                            <div 
                                onMouseEnter={() => this.setImageHovered(file.md5)} 
                                onMouseLeave={() => this.setImageHovered('')} 
                                style={{border: '1px solid #607D8B'}} 
                                className={`mx-auto p-2 d-flex justify-content-center align-items-center square-15 position-relative ${this.state.imageHovered === file.md5 ? 'image-hover' : ''}`}
                            >
                                {this.getFileThumbnail(file)}
                                {file.main ? <></> :
                                <motion.div 
                                    transition={t.transition} 
                                    exit={t.fade_out} 
                                    animate={t.normalize} 
                                    initial={t.fade_out} 
                                    className="file-labels position-absolute top-0 m-0 w-100"
                                >
                                    <p style={{textOverflow: 'ellipsis', overflow: 'hidden', width: '80%'}} className="mb-0 ms-1 mt-2 text-nowrap">{file.name}</p>
                                </motion.div>}
                                {this.state.imageHovered === file.md5 ?
                                <motion.div 
                                    transition={t.transition} 
                                    exit={t.fade_out} 
                                    animate={t.normalize} 
                                    initial={t.fade_out} 
                                    className="position-absolute top-0 end-0 m-0 w-100"
                                >
                                    <MDBBtn 
                                        onClick={e => this.props.removeFile(file.md5, e)} 
                                        className="text-danger p-2 ms-auto d-block" 
                                        color="link"  
                                        style={{zIndex: 20}}
                                        size="lg"
                                    >
                                        <MDBIcon size="lg" far icon="trash-alt" />
                                    </MDBBtn>
                                </motion.div>
                                : <></>}
                                {file.main ? <></> :
                                <motion.p
                                    transition={t.transition} 
                                    exit={t.fade_out} 
                                    animate={t.normalize} 
                                    initial={t.fade_out} 
                                    style={{textOverflow: 'ellipsis'}} 
                                    className="file-labels position-absolute bottom-0 m-0 w-100 text-center"
                                >{h.getFileSize(file.size)}</motion.p>}
                            </div>
                        </motion.div>
                    ))}
                </div>
            </MDBContainer>
        );
    }
}

export default FileList;