import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBTextArea,
    MDBValidation,
    MDBValidationItem,
    MDBSpinner
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import { new_note } from '../../../redux/actions';
import Spinner from '../../../components/Spinner';
import { note_schema } from '../../../utilities/validations';
import axios from 'axios';
import h from '../../../utilities/helpers';
import NewFileList from './NewFileList';

const allowedExtensions = process.env.REACT_APP_ALLOWED_EXTENSIONS.split(' ');

class NewNoteModal extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * working: Boolean - Whether a new note is in the process of being submitted
             * note: Object - The input value and current error, if any, of the note
             * files: Array - List of files that the user has selected
             * processingFiles: Boolean - Whether user-selected files are currently being processed 
             */
            working: false,
            note: {
                value: '',
                error: 'Please enter a note'
            },
            files: [],
            processingFiles: false
        }
    }

    // Set the error message in case the user clicks Submit without entering anything
    componentDidMount(){
        document.getElementById('note-text').setCustomValidity(this.state.note.error)
    }

    /**
     * 
     * @param {Keyboard Event} e 
     * 
     * Triggered when the user types anything in the text area
     * Sets the changes into state
     * Validates the inputs
     * If there are any errors, setCustomValidity of the textarea (errors are not shown until the user clicks Submit)
     * If no errors, setCustomValidity to falsy
     */
    textChange = e => {
        this.setState({
            ...this.state,
            note: {
                ...this.state.note,
                value: e.target.value
            }
        }, () => {
            try {
                note_schema.validateSync(
                    {
                        note: this.state.note.value
                    }, 
                    {
                        abortEarly: false
                    }
                );
                this.setState({
                    ...this.state,
                    note: {
                        ...this.state.note,
                        error: ''
                    }
                }, () => document.getElementById('note-text').setCustomValidity(''));
            } catch(err){
                this.setState({
                    ...this.state,
                    note: {
                        ...this.state.note,
                        error: err.inner[0].message
                    }
                }, () => document.getElementById('note-text').setCustomValidity(this.state.note.error));
            }
        });
    } 

    /**
     * Triggered when the user clicks the Submit button
     * 
     * Validates the inputs
     * Creates form data object with the note and any files
     * Submits the note to the server
     * Enters the new note into application state
     */
    submit = () => {
        document.getElementById('note-form').classList.add('was-validated');
        if (!this.state.working && !this.state.note.error) this.setState({
            ...this.state,
            working: true
        }, () => {
            try {
                note_schema.validateSync(
                    {
                        note: this.state.note.value
                    }, 
                    {
                        abortEarly: false
                    }
                );
                const fd = new FormData();
                fd.append('note', this.state.note.value);
                this.state.files.forEach(file => {
                    fd.append('files', file.file, file.name);
                    if (file.file.type.includes('video')) fd.append('thumbnails', file.thumbnail, file.name);
                });
                axios
                    .post('/notes', fd)
                    .then(res => this.setState({
                        ...this.state,
                        working: false,
                        note: {
                            value: '',
                            error: 'Please enter a note'
                        },
                        files: []
                    }, () => {
                        document.getElementById('note-text').setCustomValidity(this.state.note.error)
                        document.getElementById('note-form').classList.remove('was-validated');
                        this.props.new_note(res.data.note);
                    }))
                    .catch(err => this.setState({
                        ...this.state,
                        working: false
                    }, () => {
                        console.log('Insert note error', err);
                        alert('An error occurred. Please try again later.');
                    }));
            } catch(err){
                this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log('Submit error', err);
                    alert('An error occurred. Please try again later');
                });
            }
        });
    }

    /**
     * Fired when the user clicks the Insert Files button
     * 
     * Creates an invisible virtual file input
     * Adds a change event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     * Validates and processes files that the user selects, and sets them into state
     * Removes the input after the files are selected
     */
    selectFiles = () => {
        if (!this.state.working && !this.state.processingFiles){
            let input = document.createElement('input');
            input.type = 'file';
            input.multiple = true;
            input.style.visibility = "hidden";
            input.style.position = "fixed";
            document.body.appendChild(input);
            input.onchange = e => {
                this.setState({
                    ...this.state,
                    processingFiles: true
                }, async () => {
                    let files = [];
                    const fileArray = Array.from(e.target.files);
                    for (let i = 0; i < fileArray.length; i++){
                        const file = fileArray[i];
                        if (allowedExtensions.indexOf(file.type.toLowerCase()) === -1){
                            alert(`Invalid file format. Allowed: ${process.env.REACT_APP_ALLOWED_EXTENSIONS.split(' ').map(e => '.' + e.split('/')[1].split('+')[0]).join(', ')}`);
                        } else if (file.size > Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE)) alert(`Max individual file size exceeded. (Max: ${Math.round((Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE)) / (1024 * 1024))}MB)`) ;
                        else {
                            const md5 = await h.getMD5(file);
                            files.push({
                                name: file.name,
                                file: file,
                                path: URL.createObjectURL(file),
                                md5: md5,
                                size: file.size,
                                type: file.type,
                                thumbnail: file.type.includes('video') ? await h.getVideoThumbnail(file) : false
                            });
                        }
                    }
                    if (this.state.files.length + files.filter(file => !this.state.files.find(f => f.md5 === file.md5)).length > Number(process.env.REACT_APP_MAX_FILE_COUNT)) alert(`You can only upload ${process.env.REACT_APP_MAX_FILE_COUNT} files at a time`);
                    this.setState({
                        ...this.state,
                        files: [
                            ...this.state.files,
                            ...files.filter(file => !this.state.files.find(f => f.md5 === file.md5))
                        ].filter((file, f) => f < Number(process.env.REACT_APP_MAX_FILE_COUNT)),
                        processingFiles: false
                    }, () =>  document.body.removeChild(input));
                });
            }
            input.click();
        }
    }

    /**
     * 
     * @param {String} md5 - md5 hash of the file to be removed
     * 
     * Triggered when the user clicks the trash can in the top right corner of one of the files
     * Removes the file and stops the file from being played if it is playing
     */
    removeFile = md5 => {
        if (!this.state.working) this.setState({
            ...this.state,
            files: this.state.files.filter(file => file.md5 !== md5)
        });
    } 

    render(){
        return (
            <MDBModal 
                show={this.props.modalShown} 
                setShow={this.props.setShowModal} 
                tabIndex='-1'
            >
                <MDBModalDialog size="xl">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>New Note</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={this.props.toggleShowModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBValidation 
                                method="dialog" 
                                id="note-form" 
                                name="note-form" 
                                className="mt-4 w-100" 
                                onSubmit={this.submit}
                            >
                                <MDBValidationItem
                                    className="pb-4 w-100"
                                    feedback={this.state.note.error}
                                    invalid={true}
                                >
                                    <MDBTextArea
                                        name="note-text"
                                        onChange={this.textChange}
                                        label="Note"
                                        id="note-text"
                                        value={this.state.note.value}
                                        size="lg"
                                        className={`w-100 ${!this.state.note.error ? "mb-0" : ""}`}
                                    ></MDBTextArea>
                                </MDBValidationItem>
                            </MDBValidation>
                            {this.state.files.length ?
                            <NewFileList removeFile={this.removeFile} files={this.state.files} /> : <></>}
                        </MDBModalBody>
                        <MDBModalFooter className="d-flex justify-content-between align-items-center">
                            <MDBBtn
                                color="link"
                                rippleColor="primary"
                                onClick={this.selectFiles}
                            >
                                {this.state.processingFiles ?
                                <>
                                    <MDBSpinner 
                                        grow 
                                        size="sm" 
                                        color="primary" 
                                        className="me-2"
                                    />
                                    Processing
                                </> :
                                <>
                                    <i className="fas fa-images fa-lg me-2" />
                                    Select Files
                                </>}
                            </MDBBtn>
                            <div className="d-flex">
                                <MDBBtn 
                                    onClick={this.submit} 
                                    color="success" 
                                    className="me-2"
                                    disabled={this.state.working}
                                >
                                    {this.state.working ?
                                    <>
                                        <Spinner size="sm" className="me-2" />
                                        Working
                                    </> :
                                    <>
                                        <i className="fas fa-paper-plane me-2" />
                                        Submit
                                    </>}
                                </MDBBtn>
                                <MDBBtn className="bg-gray" onClick={this.props.toggleShowModal}>
                                    Close
                                </MDBBtn>
                            </div>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notes: state.notes
    }
  }
  
  export default connect(mapStateToProps, { new_note })(NewNoteModal);