import React from 'react';
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBListGroup,
    MDBListGroupItem,
    MDBRipple,
    MDBBtn
} from 'mdb-react-ui-kit';
import h from '../../../utilities/helpers';

const NoteList = props => (
    <MDBCard className="h-100 d-flex flex-column">
        <MDBCardHeader className="d-flex justify-content-between align-items-center">
            <h5 className="m-0">Notes</h5>
            <MDBBtn
                color="primary"
                onClick={props.toggleNewNoteModal}
            >
                <i className="fas fa-plus me-2" />
                New Note
            </MDBBtn>
        </MDBCardHeader>
        <MDBCardBody className="flex-grow-1 h-0 overflow-y-auto p-0">
            <MDBListGroup light>
                {props.notesToShow.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map(note => (
                    <MDBRipple key={note._id}>
                        <MDBListGroupItem 
                            action 
                            className="px-4 cursor-pointer"
                            onClick={() => props.selectNote(note._id)}
                            active={props.noteSelected === note._id}
                            style={{
                                borderRadius: 0,
                                border: 'none'
                            }}
                        >
                            <div className="d-flex justify-content-between">
                                <p className="text-blusteel m-0">{h.makeDateHR(note.timestamp)}</p>
                                <p className="text-blusteel m-0">{h.getTimeHR(note.timestamp)}</p>
                            </div>
                            <p className="m-0">{h.shortString(note.text)}</p>
                            {note.files.length ?
                            <p className="m-0 w-max-content ms-auto">
                                <i className="fas fa-images me-2 text-success" />
                                {note.files.length}
                            </p> : <></>}
                        </MDBListGroupItem>
                    </MDBRipple>
                ))}
            </MDBListGroup>
        </MDBCardBody>
    </MDBCard>
);

export default NoteList;