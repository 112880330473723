import React from 'react';
import {
    MDBContainer,
    MDBInputGroup,
    MDBInput
} from 'mdb-react-ui-kit';
import { search_change } from '../../redux/actions';
import { connect } from 'react-redux';

const SearchBar = props => (
    <MDBContainer>
        <MDBInputGroup 
            textBefore={<i id="search-icon" style={{transition: '0.2s'}} className="fas fa-search"></i>} 
            className="mt-4 w-75 mx-auto" id="search-group" size="lg"
        >
            <MDBInput 
                label="Search"
                size="lg"
                className="w-100"
                value={props.search} 
                onChange={props.search_change}
            />
        </MDBInputGroup>
    </MDBContainer>
);


const mapStateToProps = state => ({
    search: state.search
});

export default connect(mapStateToProps, {search_change})(SearchBar);