import {
    SET_HISTORY,
    SET_NOTES,
    SEARCH_CHANGE,
    NEW_NOTE,
    REMOVE_NOTE,
    UPDATE_NOTE
} from './actions';

/**
 * notes: false | Array - List of all the notes
 * search: String - The text currently in the search bar
 */
const initialState = {
    notes: false,
    history: false,
    location: false,
    search: ''
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_HISTORY:
            return {
                ...state,
                history: action.data.history,
                location: action.data.location
            }
        case REMOVE_NOTE:
            return {
                ...state,
                notes: state.notes.filter(note => note._id !== action.data)
            }
        case NEW_NOTE:
            return {
                ...state,
                notes: [
                    ...state.notes,
                    action.data
                ]
            }
        case UPDATE_NOTE:
            return {
                ...state,
                notes: [
                    ...state.notes.filter(note => note._id !== action.data._id),
                    action.data
                ]
            }
        case SET_NOTES:
            return {
                ...state,
                notes: action.data
            }
        case SEARCH_CHANGE:
            return {
                ...state,
                search: action.data
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;