import React from 'react';
import {
    MDBContainer,
    MDBBtn
} from 'mdb-react-ui-kit';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import NoteList from './notes/NoteList';
import t from '../../utilities/transitions';
import Note from './notes/Note';

class Notes extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * noteSelected: false | String - _id of the Note that the user selected
             */
            noteSelected: false
        }
    }

    // If user deletes a note, set state.noteSelected to false
    componentDidUpdate(prevProps){
        if (
            prevProps.notes.find(note => note._id === this.state.noteSelected) && 
            !this.props.notes.find(note => note._id === this.state.noteSelected)
        ) this.setState({
            ...this.state,
            noteSelected: false
        });
    }

    selectNote = note => this.setState({
        ...this.state,
        noteSelected: note
    });

    render(){
        const notes = this.props.notes.filter(note => {
            if (this.props.search) return new RegExp(this.props.search, 'i').test(note.text);
            return true;
        });

        if (!notes.length) return (
            <motion.div
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out}
            >
                <h4 className="text-center my-4">No notes found</h4>
                <MDBBtn 
                    size="lg"
                    color="primary"
                    className="d-block mx-auto"
                    onClick={this.props.toggleNewNoteModal}
                >
                    <i className="fas fa-plus me-2" />
                    New Note
                </MDBBtn>
            </motion.div>
        );

        return (
            <MDBContainer 
                fluid
                className="flex-grow-1 h-0 pt-4 pb-3"
            >
                <div className="row h-100">
                    <div className="col-lg-4 col-12 h-100">
                        <NoteList 
                            notesToShow={notes} 
                            toggleNewNoteModal={this.props.toggleNewNoteModal} 
                            selectNote={this.selectNote}
                            noteSelected={this.state.noteSelected}
                        />
                    </div>
                    {this.state.noteSelected ?
                    <div className="col-lg-8 col-12 h-100">
                        <Note key={this.state.noteSelected} note={this.state.noteSelected} />
                    </div> : <></>}
                </div>
            </MDBContainer>
        );
    }
}

const mapStateToProps = state => ({
    notes: state.notes,
    search: state.search
});

export default connect(mapStateToProps, {})(Notes);