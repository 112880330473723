import React from 'react';
import { motion } from 'framer-motion';
import h from '../../../utilities/helpers'
import t from '../../../utilities/transitions';
import { connect } from 'react-redux';
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBBtn,
    MDBTextArea,
    MDBValidation,
    MDBValidationItem,
    MDBSpinner
} from 'mdb-react-ui-kit';
import Spinner from '../../../components/Spinner';
import axios from 'axios';
import { remove_note, update_note } from '../../../redux/actions';
import { note_schema } from '../../../utilities/validations';
import FileList from './FileList';
import NewFileList from './NewFileList';

const allowedExtensions = process.env.REACT_APP_ALLOWED_EXTENSIONS.split(' ');

class Note extends React.Component{
    constructor(props){
        super();
        const note = props.notes.find(note => note._id === props.note);
        /**
         * deleting: Boolean - Whether the note is in the process of being deleted
         * editing: Boolean - Whether the user is in the process of editing the note
         * updating: Boolean - Whether edits are in the process of being submitted to the server
         * note: Object - The input value and current error, if any, of the note
         * files: Array - List of files attached to the note and that the user has selected
         * processingFiles: Boolean - Whether user-selected files are currently being processed 
         */
        this.state = {
            deleting: false,
            editing: false,
            updating: false,
            note: {
                value: note.text,
                error: ''
            },
            files: note.files.map(file => ({
                ...file,
                md5: file.main.split('.')[0]
            })),
            processingFiles: false
        }
    }

    /**
     * Triggered when the user clicks the Delete button
     * Request to the server to delete the note
     * If successful, remove the note from application state
     */
    deleteNote = () => {
        if (!this.state.deleting) this.setState({
            ...this.state,
            deleting: true
        }, () => {
            axios
                .get("/delete/" + this.props.note)
                .then(() => this.props.remove_note(this.props.note))
                .catch(err => this.setState({
                    ...this.state,
                    deleting: false
                }, () => {
                    console.log("Delete note error", err);
                    alert("An error occurred. Please try again later")
                }))
        });
    }

    /**
     * Triggered when the user clicks the Edit or Cancel Edit button
     * Toggles state.editing
     */
    toggleEdit = () => this.setState({
        ...this.state,
        editing: !this.state.editing
    });

    /**
     * 
     * @param {Keyboard Event} e 
     * 
     * Triggered when the user types anything in the text area
     * Sets the changes into state
     * Validates the inputs
     * If there are any errors, setCustomValidity of the textarea (errors are not shown until the user clicks Submit)
     * If no errors, setCustomValidity to falsy
     */
    textChange = e => {
        this.setState({
            ...this.state,
            note: {
                ...this.state.note,
                value: e.target.value
            }
        }, () => {
            try {
                note_schema.validateSync(
                    {
                        note: this.state.note.value
                    }, 
                    {
                        abortEarly: false
                    }
                );
                this.setState({
                    ...this.state,
                    note: {
                        ...this.state.note,
                        error: ''
                    }
                }, () => document.getElementById('note-text-edit').setCustomValidity(''));
            } catch(err){
                this.setState({
                    ...this.state,
                    note: {
                        ...this.state.note,
                        error: err.inner[0].message
                    }
                }, () => document.getElementById('note-text-edit').setCustomValidity(this.state.note.error));
            }
        });
    } 

    /**
     * Triggered when the user clicks the green Update button while in Editing state
     * 
     * Validates the inputs
     * Creates form data object with the note and any files
     * Submits the updated note to the server
     * Updates the note in application state
     *
     */
    update = () => {
        document.getElementById('note-form-edit').classList.add('was-validated');
        if (!this.state.updating && !this.state.note.error) this.setState({
            ...this.state,
            updating: true
        }, () => {
            try {
                note_schema.validateSync(
                    {
                        note: this.state.note.value
                    }, 
                    {
                        abortEarly: false
                    }
                );

                const note = this.props.notes.find(n => n._id === this.props.note);                    

                const fd = new FormData();
                fd.append('note', this.state.note.value);
                fd.append('noteID', note._id);

                this.state.files.forEach(file => {
                    if (!file.main){
                        fd.append('files', file.file, file.name);
                        if (file.file.type.includes('video')) fd.append('thumbnails', file.thumbnail, file.name);
                    }
                });

                note.files
                        .filter(file => !this.state.files.find(f => f.main === file.main))
                        .forEach(file => fd.append('filesRemoved', file.main));

                axios
                    .post('/edit', fd)
                    .then(res => this.setState({
                        ...this.state,
                        updating: false,
                        editing: false,
                        files: res.data.note.files.map(file => ({
                            ...file,
                            md5: file.main.split('.')[0]
                        }))
                    }, () => {
                        this.props.update_note(res.data.note);
                    }))
                    .catch(err => this.setState({
                        ...this.state,
                        updating: false
                    }, () => {
                        console.log('Insert note error', err);
                        alert('An error occurred. Please try again later.');
                    }));
            } catch(err){
                this.setState({
                    ...this.state,
                    working: false
                }, () => {
                    console.log('Submit error', err);
                    alert('An error occurred. Please try again later');
                });
            }
        });
    }

    /**
     * 
     * @param {String} md5 - md5 hash of the file to be removed
     * 
     * Triggered when the user clicks the trash can in the top right corner of one of the files
     * Removes the file and stops the file from being played if it is playing
     */
    removeFile = md5 => {
        if (!this.state.working) this.setState({
            ...this.state,
            files: this.state.files.filter(file => file.md5 !== md5)
        });
    } 

    /**
     * Fired when the user clicks the Insert Files button
     * 
     * Creates an invisible virtual file input
     * Adds a change event that sets the selected file into state
     * Appends to document body (necessary for iDevices and possibly others)
     * Clicks the input
     * Validates and processes files that the user selects, and sets them into state
     * Removes the input after the files are selected
     */
    selectFiles = () => {
        if (!this.state.working && !this.state.processingFiles){
            let input = document.createElement('input');
            input.type = 'file';
            input.multiple = true;
            input.style.visibility = "hidden";
            input.style.position = "fixed";
            document.body.appendChild(input);
            input.onchange = e => {
                this.setState({
                    ...this.state,
                    processingFiles: true
                }, async () => {
                    let files = [];
                    const fileArray = Array.from(e.target.files);
                    for (let i = 0; i < fileArray.length; i++){
                        const file = fileArray[i];
                        if (allowedExtensions.indexOf(file.type.toLowerCase()) === -1){
                            alert(`Invalid file format. Allowed: ${process.env.REACT_APP_ALLOWED_EXTENSIONS.split(' ').map(e => '.' + e.split('/')[1].split('+')[0]).join(', ')}`);
                        } else if (file.size > Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE)) alert(`Max individual file size exceeded. (Max: ${Math.round((Number(process.env.REACT_APP_MAX_INDIVIDUAL_FILE_SIZE)) / (1024 * 1024))}MB)`) ;
                        else {
                            const md5 = await h.getMD5(file);
                            files.push({
                                name: file.name,
                                file: file,
                                path: URL.createObjectURL(file),
                                md5: md5,
                                size: file.size,
                                type: file.type,
                                thumbnail: file.type.includes('video') ? await h.getVideoThumbnail(file) : false
                            });
                        }
                    }
                    if (this.state.files.length + files.filter(file => !this.state.files.find(f => f.md5 === file.md5)).length > Number(process.env.REACT_APP_MAX_FILE_COUNT)) alert(`You can only upload ${process.env.REACT_APP_MAX_FILE_COUNT} files at a time`);
                    this.setState({
                        ...this.state,
                        files: [
                            ...this.state.files,
                            ...files.filter(file => !this.state.files.find(f => f.md5 === file.md5))
                        ].filter((file, f) => f < Number(process.env.REACT_APP_MAX_FILE_COUNT)),
                        processingFiles: false
                    }, () =>  document.body.removeChild(input));
                });
            }
            input.click();
        }
    }

    render(){
        console.log(this.state.files);
        const note = this.props.notes.find(note => note._id === this.props.note);
        return (
            <motion.div
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out}
                className="h-100"
            >
                <MDBCard className="h-100 d-flex flex-column">
                    <MDBCardHeader className="d-flex justify-content-between align-items-center">
                        <h5 className="m-0">{h.makeDateHR(note.timestamp)} | {h.getTimeHR(note.timestamp)}</h5>
                        <div className="d-flex">
                            <MDBBtn
                                color="link"
                                rippleColor="primary"
                                onClick={this.toggleEdit}
                            >
                                <i className="fas fa-edit me-2" />
                                {this.state.editing ? "Cancel Edit" : "Edit"}
                            </MDBBtn>
                            <MDBBtn
                                color="danger"
                                disabled={this.state.deleting}
                                onClick={this.deleteNote}
                                className="ms-2"
                            >
                                {this.state.deleting ?
                                <>
                                    <Spinner size="sm" className="me-2" />
                                    Deleting
                                </> :
                                <>
                                    <i className="far fa-trash-alt me-2" />
                                    Delete
                                </>}
                            </MDBBtn>
                        </div>
                    </MDBCardHeader>
                    <MDBCardBody className="flex-grow-1 h-0">
                        {this.state.editing ?
                        <motion.div
                            initial={t.fade_out}
                            animate={t.normalize}
                            exit={t.fade_out}
                        >
                            <MDBValidation 
                                method="dialog" 
                                id="note-form-edit" 
                                name="note-form-edit" 
                                className="w-100" 
                                onSubmit={this.update}
                            >
                                <MDBValidationItem
                                    className="pb-4 w-100"
                                    feedback={this.state.note.error}
                                    invalid={true}
                                >
                                    <MDBTextArea
                                        name="note-text-edit"
                                        onChange={this.textChange}
                                        label="Note"
                                        id="note-text-edit"
                                        value={this.state.note.value}
                                        size="lg"
                                        className={`w-100 ${!this.state.note.error ? "mb-0" : ""}`}
                                    ></MDBTextArea>
                                </MDBValidationItem>
                            </MDBValidation>
                            {this.state.files.length ?
                            <NewFileList removeFile={this.removeFile} files={this.state.files} /> : <></>}
                            <div className="d-flex">
                                <MDBBtn
                                    color="link"
                                    rippleColor="primary"
                                    onClick={this.selectFiles}
                                >
                                    {this.state.processingFiles ?
                                    <>
                                        <MDBSpinner 
                                            grow 
                                            size="sm" 
                                            color="primary" 
                                            className="me-2"
                                        />
                                        Processing
                                    </> :
                                    <>
                                        <i className="fas fa-images fa-lg me-2" />
                                        Select Files
                                    </>}
                                </MDBBtn>
                                <MDBBtn
                                    color="success"
                                    disabled={this.state.updating}
                                    onClick={this.update}
                                    className="ms-2"
                                >
                                    {this.state.updating ?
                                    <>
                                        <Spinner className="me-2" size="sm" />
                                        Updating
                                    </> :
                                    <>
                                        <i className="fas fa-paper-plane me-2" />
                                        Update
                                    </>
                                    }
                                </MDBBtn>
                            </div>
                        </motion.div> :
                        <motion.div
                            initial={t.fade_out}
                            animate={t.normalize}
                            exit={t.fade_out}
                        >
                            <p>{note.text}</p>
                            {note.files.length ?
                            <FileList files={note.files} /> : <></>}
                        </motion.div>}
                    </MDBCardBody>
                </MDBCard>
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    notes: state.notes
});

export default connect(mapStateToProps, { remove_note, update_note })(Note);