import md5 from 'md5';
import VideoSnapshot from 'video-snapshot';

const h = {}

/**
 * 
 * @param {String} string 
 * @returns The first up to 99 characters of that string
 */
h.shortString = string => {
    string = String(string);
    if (string.length > 100) return string.substring(0, 99) + '...';
    else return string;
}

/**
 * 
 * @param {JavaScript date} date 
 * @returns a human readable date in the format "MM/DD/YYYY"
 */
h.makeDateHR = (date) => {
    date = new Date(date);
    let months = date.getMonth() + 1;
    let days = date.getDate();
    let years = date.getFullYear();
    return months + '/' + days + '/' + years
}

/**
 * 
 * @param {JavaScript date} date 
 * @returns a human readable time in the format "0:00AM"
 */
h.getTimeHR = date => {
    date = new Date(date);
    let meridian = 'AM';
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (hours >= 12) meridian = 'PM';
    if (!hours) hours = 12;
    if (hours > 12){
        hours -= 12;
    }
    if (String(minutes).length === 1) minutes = `0${minutes}`
    return hours + ':' + minutes + meridian;
}

/**
 * 
 * @param {File} file - Video file
 * 
 * Creates <video>
 * Sets src of video to file
 * Once duration is determined, get VideoSnapshot
 * 
 * @returns Thumbnail of video taken halfway through the video
 */
h.getVideoThumbnail = file => new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.ondurationchange = async function(){
        try {
            const thumbnail = await new VideoSnapshot(file).takeSnapshot(this.duration / 2);
            resolve(h.dataURLtoFile(thumbnail, 'file.png'));
        } catch(err){
            console.log('Video thumbnail error', err);
            reject(err);
        }
    }
});

/**
 * 
 * @param {File} file 
 * @returns md5 hash of the file
 */
h.getMD5 = file => new Promise((resolve, reject) => {
    fetch(URL.createObjectURL(file)).then((res)=> res.text().then(text => resolve(md5(text))).catch(err => {
        console.log(err);
        return reject(err);
    })).catch(err => {
        console.log(err);
        reject(err);
    })
});

/**
* 
* @param {Number} size - Size of the file to be measured
* @returns Size of file in Bytes/KB/MB/etc
*/
h.getFileSize = size => {
   size = Number(size);
   const units = ['Bytes', 'KB', 'MB', 'GB'];
   let scale = 0;
   while (size > 900){
       size /= 1024;
       scale++;
   }
   return (Math.round(size * 100) / 100) + ' ' + units[scale];
}

/**
 * 
 * @param {String} dataURL - DataURL
 * @param {String} filename - File name
 * 
 * Converts dataURL to Uint8Array, then feeds Uint8Array, filename, and mimetype into File constructor
 * 
 * @returns JavaScript file object
 */
h.dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length; 
    let u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

export default h;