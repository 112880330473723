import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import reducer from './redux/reducer';
import { legacy_createStore as createStore } from 'redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';

/**
 * Set Redux store
 * Set Material Design theme so that the colors match with the MDB colors
 */

const store = createStore(reducer);

const theme = createTheme({
  palette: {
    primary: {
      main: "#1266f1"
    },
    secondary: {
      main: "#b23cfd"
    },
    info: {
      main: "#39c0ed"
    },
    warning: {
      main: "#ffa900"
    },
    danger: {
      main: "#f93154"
    },
    success: {
      main: "#00b74a"
    },
    light: {
      main: "#f9f9f9"
    },
    dark: {
      main: "#262626"
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <BrowserRouter basename='/'>
                <App />
            </BrowserRouter>
        </ThemeProvider>
    </Provider>
);