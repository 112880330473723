import React from 'react';
import {
    MDBContainer,
    MDBRipple
} from 'mdb-react-ui-kit';
import FileModal from './FileModal';

class FileList extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * fileSelected: false | String - Filename of the file selected
             * fileModalShown: Boolean - Whether the file modal is shown
             */
            fileSelected: false,
            fileModalShown: false
        }
    }

    /**
     * 
     * @param {String} file - The file name
     * 
     * Sets the file into state, then displays the file modal 
     */
    selectFile = file => this.setState({
        ...this.state,
        fileSelected: file
    }, this.toggleFileModal);

    toggleFileModal = () => this.setState({
        ...this.state,
        fileModalShown: !this.state.fileModalShown
    });

    setFileModal = option => this.setState({
        ...this.state,
        fileModalShown: option
    });

    render(){
        return (
            <>
                <FileModal 
                    file={this.state.fileSelected}
                    modalShown={this.state.fileModalShown}
                    setShowModal={this.setFileModal}
                    toggleShowModal={this.toggleFileModal}
                />
                <hr />
                <div className="bg-emphasis p-3 rounded-6">
                    <h5 className="text-blusteel">
                        <i className="fas fa-images me-2 text-success"></i>
                        Media
                    </h5>
                    <MDBContainer fluid>
                        <div className="row">
                            {this.props.files.map(file => (
                                <div key={file.main} className="col-6 col-md-3 col-lg-2 mt-2 d-flex justify-content-center px-1">
                                    <MDBRipple rippleColor="primary" rippleTag="div" onClick={() => this.selectFile(file)} className="square-10 cursor-pointer hover-generic p-2">
                                        {file.type === 'audio' ?
                                        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                                            <i className="fas fa-volume-up fa-3x d-block"></i>
                                        </div> :
                                        <div className="fit-images" style={{backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/thumbnails/${file.thumbnail}")`}}></div>}
                                    </MDBRipple>
                                </div>
                            ))}
                        </div>
                    </MDBContainer>
                </div> 
            </>
        );
    }
}

export default FileList;