import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import t from '../utilities/transitions';
import axios from 'axios';
import Loading from './home/Loading';
import { set_notes } from '../redux/actions';
import SearchBar from './home/SearchBar';
import NewNoteModal from './home/notes/NewNoteModal';
import Notes from './home/Notes';

class Home extends React.Component{
    constructor(){
        super();
        this.state = {
            /**
             * newNoteModalShown: Boolean - Whether the New Note modal is currently displayed
             */
            newNoteModalShown: false
        }
    }

    /**
     * Load the notes when the application mounts
     */
    componentDidMount(){
        if (!this.props.notes) this.loadNotes();
    }

    /**
     * When a new note is added, hide the New Note Modal
     */
    componentDidUpdate(prevProps){
        if (prevProps.notes && prevProps.notes.length < this.props.notes.length) this.toggleNewNoteModal();
    }

    /**
     * Requests the notes from the server
     * If it fails, try again in 2 seconds
     */
    loadNotes = () => axios
                        .get('/notes')
                        .then(res => this.props.set_notes(res.data.notes))
                        .catch(err => {
                            console.log('Load notes error', err);
                            setTimeout(this.loadNotes, 2000);
                        });
    
    setNewNoteModal = option => this.setState({
        ...this.state,
        newNoteModalShown: option
    });

    toggleNewNoteModal = () => this.setState({
        ...this.state,
        newNoteModalShown: !this.state.newNoteModalShown
    });

    render(){
        return (
            <motion.div
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out}
                className="h-100 d-flex flex-column"
            >
                {this.props.notes ?
                <>
                    <NewNoteModal
                        modalShown={this.state.newNoteModalShown}
                        setShowModal={this.setNewNoteModal}
                        toggleShowModal={this.toggleNewNoteModal}
                    />
                    <SearchBar />
                    <Notes toggleNewNoteModal={this.toggleNewNoteModal} />
                </> :
                <Loading />}
            </motion.div>
        );
    }
}

const mapStateToProps = state => ({
    history: state.history,
    location: state.location,
    notes: state.notes
});

export default connect(mapStateToProps, { set_notes })(Home);