import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter
} from 'mdb-react-ui-kit';
import File from './File';

const FileModal = props => (
    <MDBModal 
        show={props.modalShown} 
        setShow={props.setShowModal} 
        tabIndex='-1'
    >
        <MDBModalDialog size="xl">
            <MDBModalContent>
                <MDBModalHeader>
                    <MDBModalTitle>View File</MDBModalTitle>
                    <MDBBtn className='btn-close' color='none' onClick={props.toggleShowModal}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                    {props.file ?
                    <File file={props.file} /> : <></>}
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn className="bg-gray" onClick={props.toggleShowModal}>
                        Close
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
);

export default FileModal;