import React from 'react';

/**
 * 
 * @param {File} file 
 * @returns The appropriate tag given the type of file
 */
const File = ({ file }) => {
    switch(file.type){
        case 'video':
            return (
                <video 
                    className="max-w-100 max-h-100 d-block mx-auto" 
                    src={process.env.REACT_APP_BUCKET_HOST + "/videos/" + file.main} 
                    id={file.main.split('.')[0]}
                    controls
                />
            );
        case 'audio':
            return (
                <audio 
                    src={process.env.REACT_APP_BUCKET_HOST + "/audio/" + file.main} 
                    id={file.main.split('.')[0]}
                    controls
                    className="d-block mx-auto"
                />
            );
        case 'image':
            const src = process.env.REACT_APP_BUCKET_HOST + "/images/" + file.main;
            return (
                <img 
                    onClick={() => window.open(src)} 
                    src={src} 
                    className="d-block max-h-100 max-w-100 cursor-pointer mx-auto" 
                />
            );
    }
}

export default File;