export const SET_HISTORY = "SET_HISTORY";
export const set_history = (history, location) => ({
    type: SET_HISTORY,
    data: {
        history: history,
        location: location
    }
});

export const SET_NOTES = "SET_NOTES";
export const set_notes = notes => ({
    type: SET_NOTES,
    data: notes
});

export const NEW_NOTE = "NEW_NOTE";
export const new_note = note => ({
    type: NEW_NOTE,
    data: note
});

export const REMOVE_NOTE = "REMOVE_NOTE";
export const remove_note = noteID => ({
    type: REMOVE_NOTE,
    data: noteID
});

export const SEARCH_CHANGE = "SEARCH_CHANGE";
export const search_change = e => ({
    type: SEARCH_CHANGE,
    data: e.target.value
});

export const UPDATE_NOTE = "UPDATE_NOTE";
export const update_note = note => ({
    type: UPDATE_NOTE,
    data: note
});